import AppBuilder from '@appbuilder/react';
import React, { useState, useEffect } from 'react';
import './App.css';

function App() {

  useEffect(() => {    
    document.title = `BOS Meet`;
    document.getElementsByClassName('css-175oi2r')[0].style.height = '100vh';
  });

  return (
    <AppBuilder.View/>
  );
}

export default App;
